import React, { useState } from 'react';
import { COMPONENTS, Button } from 'sw-ui';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/company.module.scss';

const LABELS = {
  DISCREPANCY_FILE: 'Файл с расхождениями',
  OK: 'OK',
};

const DiscrepancyForm = (props) => {
  const [disabledSendDiscrepancyFile, setDisabledSendDiscrepancyFile] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);

  const { textError, companyId, onDownload, onCoflictDailog } = props;

  const handleDownload = () => {
    setWaitingResponse(true);
    setDisabledSendDiscrepancyFile(true);

    return onDownload(companyId)
      .finally(() => {
        setWaitingResponse(!waitingResponse);
        setDisabledSendDiscrepancyFile(!disabledSendDiscrepancyFile);
      });
  };

  return (
    <div className={ `${styles.form} ${styles['form-dialog']}` }>
      <div className={ styles['text-conflict'] }>
        {textError}
      </div>
      <div className={ `${styles.row} ${styles['button-change']} ${styles['button-conflict']}` }>
        <AjaxButton
          label={ LABELS.DISCREPANCY_FILE }
          theme={ COMPONENTS.BUTTON.THEME.SECOND }
          loading={ waitingResponse }
          onClick={ () => handleDownload() }
          disabled={ disabledSendDiscrepancyFile }
        />
        <Button
          label={ LABELS.OK }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ onCoflictDailog }
        />
      </div>
    </div>
  );
};

DiscrepancyForm.propTypes = {
  textError: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  onDownload: PropTypes.func.isRequired,
  onCoflictDailog: PropTypes.func.isRequired,
};

export default DiscrepancyForm;
