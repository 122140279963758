import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Loading } from 'sw-ui';

import RangeDatePicker from '../../../../components/RangeDatePicker';
import debtComponent from '../debt';
import ExpenseReportsPeriodDialog from './components/ExpenseReportsPeriodDialog';
import InvoicePaymentTotalForm from './invoicePaymentTotalForm';
import ReviseForm from './reviseForm';
import InvoiceForm from './invoiceForm';
import OperationInvoice from './invoice';
import NotificationForm from './notificationForm';
import IntegrationForm from './integrationForm';
import LimitAmountForm from './limitAmountForm';
import CertificateForm from './CertificateForm';
import DiscrepancyForm from './DiscrepancyForm';

import { getMonthsDifference } from '../../../../bi/utils/formatDate';
import { formatDate } from '../../../../utils/date';
import MoneyFormat from '../../../../bi/utils/money';

import {
  DEBT,
  ADDITIONALOVERDRAFTTEXT,
  ACCOUNTTEXT,
  CERTIFICATE_TYPES,
  CLOSING_SCHEMAS,
} from '../../../../bi/constants/account';
import { TRIPBUTTONS } from '../../../../bi/constants/trips';
import { FORMATDATETIME } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/company.module.scss';

const LABELS = {
  EDIT: 'ИЗМЕНИТЬ',
  ATTENTION: 'Обратите внимание',
  NOTIFICATIONS: 'ОПОВЕЩЕНИЯ',
  DOWNLOADINVOICE: 'СКАЧАТЬ СВОДНЫЙ СЧЕТ',
  DOWNLOADREVISE: 'СКАЧАТЬ АКТ СВЕРКИ',
  DOWNLOADDETAILEDREVISE: 'АКТ СВЕРКИ БИЛЕТЫ ДЕТАЛЬНО',
  DOWNLOADPREPAYINVOICE: 'СКАЧАТЬ СЧЕТ НА ПРЕДОПЛАТУ',
  VISIBILITYRESTRICTION: 'ОГРАНИЧЕНИЕ ВИДИМОСТИ',
  DOWNLOAD_MASS_CERTIFICATES: 'СКАЧАТЬ МАССОВЫЕ СПРАВКИ',
  UNLOAD_EXPENSE_REPORTS: 'Выгрузка авансовых отчетов с 1С:БП',
  INTEGRATION_1C: 'Изменение даты и времени подключения интеграции с 1С:БП ',
  FROM: 'С',
  TO: 'по',
  OK: 'OK',
  SEND_TO_EDO: 'Акт сверки успешно отправлен',
  DISCREPANCY_FILE: 'Файл с расхождениями',
  MONTHS_LIMIT: 'Период для скачивания отчета не может превышать 12 месяцев',
};

const FIELDS = {
  PROFILE: 'profile',
  WARNING: 'WARNING',
  DETAILEDREVISE: 'showDetailedReviseDialog',
  DETAIL_CERTIFICATE: 'showDetailCertificateDialog',
};

const DIALOGSIZE = {
  SMALL: '360px',
  BIG: '400px',
};

const MONTHS_LIMIT_FOR_DOWNLOAD = 12;

class Accounting extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    companyService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showConflictDialog: false,
  };

  constructor(props) {
    super(props);

    const {
      startDate,
      endDate,
      maxDate,
      accounting: {
        details,
        balance,
        funds,
        operations,
        integration,
        limitAmount,
        errorExpenseReportDialog,
      },
    } = props.companyService.get();

    this.state = {
      startDate,
      endDate,
      maxDate,
      details,
      balance,
      funds,
      operations,
      integration,
      limitAmount,
      errorExpenseReportDialog,

      showInvoicePaymentTotalDialog: false,
      showReviseDialog: false,
      showUpdDialog: false,
      showRateDialog: false,
      showIntegrationDialog: false,
      showLimitAmountDialog: false,
      fieldUpd: '',
      fieldRate: '',
      showConflictDialog: false,
      showNotificationDialog: false,
      showAandaVsProviders: false,
      waitingResponse: false,
      loading: true,
      loadingAccountins: true,
      responceError: '',
      loadingLimitAmount: false,
      showDetailedReviseDialog: false,
      closingDocsChange: false,
      [FIELDS.DETAIL_CERTIFICATE]: false,
      showUnloadingExpenseReportsDialog: false,
      showSendToEDO: false,
      messageSendToEDO: '',
      changeInvoiceNumber: false,
      monthsLimitExceeded: false,
    };
  }

  componentDidMount() {
    const { companyService } = this.props;
    const { featureFlagsService, accountId } = this.props;

    this.unsubscribe = companyService.subscribe(this.update);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);

    companyService.loadAccounting(accountId);
    featureFlagsService.getAllFeatureFlags();
    featureFlagsService.getSettingsFeatureFlags(accountId);
  }

  componentWillUnmount() {
    this.unsubscribe();

    if (this.unsubFeatureFlags) {
      this.unsubFeatureFlags();
    }
  }

  update = ({ accounting }) => this.setState({ loading: false, ...accounting });

  updateFeatureFlags = ({ ClosingDocsChange, ChangeInvoiceNumber }) => {
    this.setState({
      closingDocsChange: ClosingDocsChange,
      changeInvoiceNumber: ChangeInvoiceNumber,
    });
  };

  toggleErrorExpenseReportDialog = () => this.props.companyService.setErrorExpenseReportsDialog();

  toggleConflictDialog = () => this.setState({
    showConflictDialog: !this.state.showConflictDialog,
  });

  toggleUnloadingExpenseReportsDialog = () => this.setState({
    showUnloadingExpenseReportsDialog: !this.state.showUnloadingExpenseReportsDialog,
  });

  loadLimitAmount = () => this.props.companyService.loadLimitAmount().then(this.toggleLoadingLimitAmount);

  onDownloadInvoicePaymentTotal = (event) => {
    const {
      companyId,
      companyService,
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    companyService.downloadInvoicePaymentTotal(companyId);
  };

  handleOperations = () => {
    const {
      startDate,
      endDate,
    } = this.state;

    const {
      companyService,
      companyId,
    } = this.props;

    const searchOperations = () => {
      companyService.searchOperations(companyId, startDate, endDate)
        .then(() => this.setState({ waitingResponse: false }))
        .catch(() => this.setState({ waitingResponse: false }));
    };

    this.setState({ waitingResponse: true }, searchOperations);
  };

  handleChangeDate = (field, value) => {
    const currentValue = this.state[field];

    if (!currentValue.isSame(value)) {
      this.setState({
        [field]: value,
      }, this.handleOperations);
    }
  };

  handleCloseDialog = () => {
    this.setState({
      showUpdDialog: false,
      fieldUpd: '',
    });
  };

  handleSaveIntegration = (value) => {
    const { companyService, companyId } = this.props;

    return companyService.saveIntegration(companyId, formatDate(value, FORMATDATETIME));
  };

  handleSaveLimitAmount = amount => this.props.companyService.saveLimitAmount(amount).then(this.toggleLimitAmountDialog);

  handleChangePeriodExpenseReports = (startDate, endDate) => {
    const { companyService, companyId } = this.props;

    return companyService.changePeriodExpenseReports(companyId, startDate, endDate);
  };

  toggleInvoicePaymentTotalDialog = () => {
    this.setState({
      showInvoicePaymentTotalDialog: !this.state.showInvoicePaymentTotalDialog,
    });
  };

  toggleReviseDialog = () => {
    this.setState({
      showReviseDialog: !this.state.showReviseDialog,
    });
  };

  toggleDialog = (field) => {
    this.setState({
      [field]: !this.state[field],
    });
  };

  toggleNotificationDialog = () => this.setState({ showNotificationDialog: !this.state.showNotificationDialog });

  togglePrepayInvoiceDialog = () => {
    this.setState({
      showPrepayInvoiceDialog: !this.state.showPrepayInvoiceDialog,
    });
  };

  toggleIntegrationDialog = () => this.setState({
    showIntegrationDialog: !this.state.showIntegrationDialog,
  });

  toggleLoadingLimitAmount = () => this.setState({
    loadingLimitAmount: !this.state.loadingLimitAmount,
  });

  toggleLimitAmountDialog = () => {
    if (!this.state.showLimitAmountDialog) {
      this.toggleLoadingLimitAmount();
      this.loadLimitAmount();
    }

    this.setState({
      showLimitAmountDialog: !this.state.showLimitAmountDialog,
    });
  };

  toggleMonthsLimitNotification = () => this.setState({
    monthsLimitExceeded: !this.state.monthsLimitExceeded,
  });

  downloadInvoicePaymentTotal = ({ format, flagStamp, startDateValue, endDateValue }) => {
    const { companyId, companyService } = this.props;

    const monthsDifferenceCount = getMonthsDifference(startDateValue, endDateValue);

    if (monthsDifferenceCount > MONTHS_LIMIT_FOR_DOWNLOAD) {
      this.toggleMonthsLimitNotification();

      return this.toggleInvoicePaymentTotalDialog();
    }

    companyService.downloadInvoicePaymentTotal({ companyId, startDateValue, endDateValue, format, flagStamp })
      .then(() => this.toggleInvoicePaymentTotalDialog())
      .catch(() => this.toggleInvoicePaymentTotalDialog());
  };

  downloadRevise = (
    startDate = '',
    endDate = '',
    type = 0,
    format,
    isDetailed,
    companiesIds,
    signature,
  ) => {
    const { companyService, companyId } = this.props;

    return companyService.downloadRevise(
        companyId,
        format,
        startDate,
        endDate,
        isDetailed,
        companiesIds,
        type,
        signature
      )
      .then(() => {
        this.toggleReviseDialog();
      })
      .catch((err) => {
        this.toggleReviseDialog();
        if (err.status === 400) {
          this.setState({
            showConflictDialog: true,
            responceError: err.body.Message,
          });
        }
      });
  };

  downloadDiscrepancyFile = async () => {
    const { companyService, companyId } = this.props;

    try {
      await companyService.downloadDiscrepancyFile(companyId);
      this.toggleConflictDialog();
    } catch (error) {
      this.setState({
        showConflictDialog: true,
      });
      throw new Error(error);
    }
  };

  onSendToEDO = ({
      startDate = '',
      endDate = '',
      type = 0,
      format,
      isDetailed,
      companiesIds,
      signature,
  }) => {
    const { companyService, companyId } = this.props;

    const monthsDifferenceCount = getMonthsDifference(startDate, endDate);

    if (monthsDifferenceCount > MONTHS_LIMIT_FOR_DOWNLOAD) {
      this.toggleMonthsLimitNotification();

      return this.toggleReviseDialog();
    }

    return companyService.sendToEDO(
        companyId,
        format,
        startDate,
        endDate,
        isDetailed,
        companiesIds,
        type,
        signature
    )
    .then(() => {
      this.setState({
        showSendToEDO: true,
        messageSendToEDO: LABELS.SEND_TO_EDO,
      });
    })
    .catch((err) => {
      this.setState({
        showSendToEDO: true,
        messageSendToEDO: err.body.Message,
      });
    })
    .finally(() => {
      this.toggleReviseDialog();
    });
  }

  closeSendToEDO = () => {
    this.setState({
      showSendToEDO: false,
      messageSendToEDO: '',
    });
  }

  downloadFlightCertificate = (startDate = '', endDate = '', format, type, signatureType) => {
    const { companyService: { downloadCertificateByEveryFlight, downloadCertificateBySingleFlight }, companyId } = this.props;

    const data = {
      CompanyId: companyId,
      SignatureType: signatureType,
      StartDate: startDate,
      EndDate: endDate,
    };

    const monthsDifferenceCount = getMonthsDifference(startDate, endDate);

    if (monthsDifferenceCount > MONTHS_LIMIT_FOR_DOWNLOAD) {
      this.toggleMonthsLimitNotification();

      return this.toggleDialog(FIELDS.DETAIL_CERTIFICATE);
    }

    const downloadFns = {
      [CERTIFICATE_TYPES.EVERY_FLIGHT]: () => downloadCertificateByEveryFlight(data, format),
      [CERTIFICATE_TYPES.SINGLE_FLIGHT]: () => downloadCertificateBySingleFlight(data, format),
    };

    const downloadFn = downloadFns[type];

    if (!downloadFn) {
      this.toggleDialog(FIELDS.DETAIL_CERTIFICATE);
      return;
    }

    downloadFn().finally(() => this.toggleDialog(FIELDS.DETAIL_CERTIFICATE));
  };

  downloadPrepayInvoice = (format, sum, flagStamp) => {
    this.props.companyService.downloadPrepayInvoice(this.props.companyId, format, sum, flagStamp)
      .then(() => this.togglePrepayInvoiceDialog())
      .catch(() => this.togglePrepayInvoiceDialog());
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading
        size={ COMPONENTS.LOADING.SIZE.LARGE }
      />
    </div>
  );

  renderEmptyList = () => <div className={ `empty-list ${styles['empty-list']}` }>Нет операций</div>;

  renderDialogSendToEDO = () => {
    const { showSendToEDO, messageSendToEDO } = this.state;

    return !showSendToEDO ? null : (
      <Dialog onClick={ this.closeSendToEDO } width={ COMPONENTS.DIALOG.MIDDLE }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <p className={ styles['text-conflict'] }>{ messageSendToEDO }</p>
          <div className={ `${styles.row}` }>
            <Button label={ TRIPBUTTONS.CLOSE } theme={ COMPONENTS.BUTTON.FLAT } onClick={ this.closeSendToEDO } />
          </div>
        </div>
      </Dialog>
    );
  }

  renderHeader = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-8'] }>
        <label>Дата</label>
      </div>
      <div className={ styles['col-1-4'] }>
        <label>Операция</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Пополнение счета</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Сумма заказа</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Номер поездки</label>
      </div>
      <div className={ styles['col-1-4'] }>
        <label>Документ</label>
      </div>
    </div>
  );

  toProfile = (event) => {
    event.preventDefault();

    this.props.toAnotherTab(FIELDS.PROFILE);
  };

  saveNotificationBalance = (balance) => {
    const { notificationsService: notifier } = this.props;

    this.props.companyService.saveNotificationBalance(this.props.companyId, balance)
      .then((res) => {
        this.setState({ showNotificationDialog: false });

        if (!res.AtLeastOneUserSelected) {
          notifier.send({
            header: `${LABELS.ATTENTION}`,
            level: notifier.levels.get(FIELDS.WARNING),
            message: (
              <div>
                Пожалуйста, выберите получателей писем о балансе на вкладке
                <a className={ styles.link } onClick={ this.toProfile }> Пользователи</a>
              </div>
            ),
          });
        }
      });
  };

  renderOperationInvoiceList = (list) => {
    if (list.length) {
      const { balance, waitingResponse, details: { Schema }, changeInvoiceNumber } = this.state;
      const {
        companyService,
        companyId,
        accountId,
      } = this.props;

      const renderPenaltyBySchema = !CLOSING_SCHEMAS.includes(Schema);

      const operations = list.map((item, index) => (
        <OperationInvoice
          key={ index }
          item={ item }
          renderPenaltyBySchema={ renderPenaltyBySchema }
          updateState={ this.handleOperations }
          companyService={ companyService }
          companyId={ companyId }
          accountId={ accountId }
          changeInvoiceNumber={ changeInvoiceNumber }
        />
      ));

      return (
        <div className={ waitingResponse ? styles.opacity : '' }>
          { this.renderHeader() }
          { operations }
          <div className={ `${styles.row} ${styles['row-balance']}` }>
            <div className={ styles['col-1-8'] }>
              <span>Итого</span>
            </div>
            <div className={ styles['col-1-4'] } />
            <div className={ styles['col-1-8'] }>
              <span>{ MoneyFormat.symbolWithMoneyWithDecimal(Math.abs(balance.Credit)) }</span>
            </div>
            <div className={ styles['col-1-8'] }>
              <span>{ MoneyFormat.symbolWithMoneyWithDecimal(balance.Debit) }</span>
            </div>
          </div>
        </div>
      );
    }

    return this.renderEmptyList();
  };

  renderAdditionalText = (text, value) => {
    if (!value) return null;

    return (
      <div className={ styles.row }>
        {text} { value }
      </div>
    );
  };

  renderAdditionalOverdraft = (funds) => {
    const additionalOverdraft = funds.AdditionalOverdraft ? MoneyFormat.symbolWithMoneyWithDecimal(funds.AdditionalOverdraft) : 0;

    return this.renderAdditionalText(ADDITIONALOVERDRAFTTEXT.ADDITIONALOVERDRAFT, additionalOverdraft);
  };

  renderDebt = () => {
    const { funds: { HasDebt, Debt, TotalDebt } } = this.state;

    const numDebt = MoneyFormat.symbolWithMoneyWithDecimal(Debt);
    if (!HasDebt) return Debt > 0 ? `${DEBT.ALL}: ${numDebt}` : DEBT.NONE;

    const numTotal = MoneyFormat.symbolWithMoneyWithDecimal(TotalDebt);
    const totalDebtText = `${DEBT.ALL}: ${numTotal}`;
    const overDebt = `(${DEBT.OVER}: ${numDebt})`;
    return `${totalDebtText} ${overDebt}`;
  };

  renderIntegrationInfo = () => {
    const { integration: { CreateDate } } = this.state;

    return (
      <div>
        <div className={ `${styles.row} ${styles['button-change']}` }>
          <div> { LABELS.INTEGRATION_1C } { formatDate(CreateDate, FORMATDATETIME) }</div>
          <Button
            label={ LABELS.EDIT }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.toggleIntegrationDialog }
          />
        </div>
        <div className={ `${styles.row} ${styles['button-change']}` }>
          <div> { LABELS.UNLOAD_EXPENSE_REPORTS } </div>
          <Button
            label={ LABELS.EDIT }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.toggleUnloadingExpenseReportsDialog }
          />
        </div>
      </div>
    );
  };

  renderVisibilityRestrictionDialog = () => {
    const { limitAmount: { enabled, amount }, loadingLimitAmount } = this.state;

    return (
      <LimitAmountForm
        loading={ loadingLimitAmount }
        enabled={ enabled }
        amount={ amount }
        companyService={ this.props.companyService }
        onSave={ this.handleSaveLimitAmount }
        onClose={ this.toggleLimitAmountDialog }
      />
    );
  };

  renderDetailedCertificateDialog = () => (
    <Dialog onClick={ () => this.toggleDialog(FIELDS.DETAIL_CERTIFICATE) } >
      <CertificateForm
        title={ ACCOUNTTEXT.DOWNLOAD_CERTIFICATE }
        onDownload={ this.downloadFlightCertificate }
      />
    </Dialog>
  );

  renderErrorExpenseReportDialog = () => {
    const { errorExpenseReportDialog } = this.state;

    return (
      errorExpenseReportDialog ?
        <Dialog onClick={ this.toggleErrorExpenseReportDialog } width={ DIALOGSIZE.SMALL }>
          <div className={ `${styles.form} ${styles['form-dialog']}` }>
            <div className={ styles['text-conflict'] }>
              {errorExpenseReportDialog}
            </div>
            <div className={ `${styles.row} ${styles['button-change']} ${styles['button-conflict']}` }>
              <Button
                label={ LABELS.OK }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleErrorExpenseReportDialog }
              />
            </div>
          </div>
        </Dialog> : null
    );
  };

  renderMonthsExceededNotification = () => {
    const { monthsLimitExceeded } = this.state;

    return monthsLimitExceeded ? (
      <Dialog onClick={ this.toggleMonthsLimitNotification } width={ DIALOGSIZE.SMALL }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <div className={ styles['text-conflict'] }>
            { LABELS.MONTHS_LIMIT }
          </div>
          <div className={ `${styles.row} ${styles['button-change']} ${styles['button-conflict']}` }>
            <Button
              label={ LABELS.OK }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ this.toggleMonthsLimitNotification }
            />
          </div>
        </div>
      </Dialog>
    ) : null;
  };

  render() {
    const { companyService, accountService, notificationsService } = this.props;

    const {
      responceError,
      startDate,
      endDate,
      maxDate,
      showInvoicePaymentTotalDialog,
      showReviseDialog,
      showConflictDialog,
      showPrepayInvoiceDialog,
      showNotificationDialog,
      showIntegrationDialog,
      showLimitAmountDialog,
      showDetailCertificateDialog,
      waitingResponse,
      loading,
      funds,
      details,
      reports,
      integration,
      loadingAccountins,
      showUnloadingExpenseReportsDialog,
    } = this.state;
    if (loading || loadingAccountins) return this.renderLoading();

    const integrationHtml = integration ? this.renderIntegrationInfo() : null;
    const integrationDialog = integration && showIntegrationDialog ? (
      <IntegrationForm
        date={ integration.CreateDate }
        onSaveIntegration={ this.handleSaveIntegration }
        toggleIntegrationDialog={ this.toggleIntegrationDialog }
      />
    ) : null;

    const unloadingExpenseReportsDialog = integration && showUnloadingExpenseReportsDialog ? (
      <ExpenseReportsPeriodDialog
        onChangeExpenseReportsPeriod={ this.handleChangePeriodExpenseReports }
        toggleUnloadingExpenseReportsDialog={ this.toggleUnloadingExpenseReportsDialog }
      />
    ) : null;

    const limitAmountDialog = showLimitAmountDialog ? this.renderVisibilityRestrictionDialog() : null;
    const detailedCertificateDialog = showDetailCertificateDialog ? this.renderDetailedCertificateDialog() : null;

    return (
      <div className={ styles['operation-wrap'] }>
        <div className={ `${styles.panel} ${styles.accounting}` }>
          <div className={ styles.form }>
            <div className={ styles.row }>
              Период овердрафта: { funds.OverdraftPeriodInDays }
            </div>
            <div className={ `${styles.row} ${styles['button-change']}` }>
              Лимит: { MoneyFormat.moneyWithDecimal(funds.Overdraft) }
              {
                funds.Overdraft ?
                  null :
                  <Button
                    label={ LABELS.NOTIFICATIONS }
                    theme={ COMPONENTS.BUTTON.THEME.FLAT }
                    onClick={ this.toggleNotificationDialog }
                  />
              }
              <Button
                label={ LABELS.VISIBILITYRESTRICTION }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleLimitAmountDialog }
              />
            </div>
            { this.renderAdditionalText(ADDITIONALOVERDRAFTTEXT.ADDITIONALPERIOD, funds.AdditionalOverdraftPeriodInDays) }
            { this.renderAdditionalOverdraft(funds) }
            <div className={ styles.row }>
              { debtComponent(funds) }
            </div>
            <div className={ styles.row }>
              { funds.HasDebt ?
                `${ACCOUNTTEXT.NEWRESERVATIONSUNAVAILABLE}` :
                `${ACCOUNTTEXT.AVAILABLEFORBOOKING}: ${MoneyFormat.moneyWithDecimal(funds.BookingAmount)}`
              }
            </div>
            { integrationHtml }
            <div className={ `${styles.row} ${styles.action}` }>
              <Button
                label={ LABELS.DOWNLOADINVOICE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleInvoicePaymentTotalDialog }
              />
              <Button
                label={ LABELS.DOWNLOADREVISE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleReviseDialog }
              />
              <Button
                label={ LABELS.DOWNLOADPREPAYINVOICE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.togglePrepayInvoiceDialog }
              />
              <Button
                label={ LABELS.DOWNLOAD_MASS_CERTIFICATES }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ () => this.toggleDialog(FIELDS.DETAIL_CERTIFICATE) }
              />
            </div>
          </div>
        </div>

        <div className={ `${styles.panel} ${styles.accounting}` }>
          <div className={ styles.title }>
            <div className={ styles['panel-name'] }>Отчетность</div>
          </div>
          <div className={ styles.form }>
            <div className={ styles.row }>
              <div className={ styles.date }>
                <RangeDatePicker
                  startDate={ startDate }
                  endDate={ endDate }
                  maxDate={ maxDate }
                  onChange={ this.handleChangeDate }
                />
              </div>
            </div>
            { this.renderOperationInvoiceList(reports) }
            { waitingResponse ? (
              <div className={ styles['big-loader'] }>
                <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
              </div>
            ) : '' }
          </div>
        </div>
        { showConflictDialog ? (
          <Dialog onClick={ this.toggleConflictDialog } width={ DIALOGSIZE.SMALL }>
            <DiscrepancyForm
              textError={ responceError }
              companyId={ this.props.companyId }
              onDownload={ this.downloadDiscrepancyFile }
              onCoflictDailog={ this.toggleConflictDialog }
            />
          </Dialog>
        ) : null}
        { showInvoicePaymentTotalDialog ?
          <Dialog onClick={ this.toggleInvoicePaymentTotalDialog }>
            <div className={ `${styles.form} ${styles['form-dialog']}` }>
              <InvoicePaymentTotalForm
                onDownload={ this.downloadInvoicePaymentTotal }
              />
            </div>
          </Dialog> : null
        }
        { showReviseDialog ?
          <Dialog onClick={ this.toggleReviseDialog } width='745px'>
            <ReviseForm
              companyId={ this.props.companyId }
              accountService={ accountService }
              onDownload={ this.downloadRevise }
              onSendToEDO={ this.onSendToEDO }
            />
          </Dialog> : null
        }
        { showPrepayInvoiceDialog ?
          <Dialog onClick={ this.togglePrepayInvoiceDialog }>
            <div className={ `${styles.form} ${styles['form-dialog']}` }>
              <InvoiceForm
                onDownload={ this.downloadPrepayInvoice }
              />
            </div>
          </Dialog> : null
        }
        { showNotificationDialog ?
          <Dialog onClick={ this.handleCloseDialog } width={ DIALOGSIZE.BIG }>
            <div className={ `${styles.form} ${styles['form-dialog']}` }>
              <NotificationForm
                onClose={ this.toggleNotificationDialog }
                onSave={ this.saveNotificationBalance }
                companyService={ companyService }
                notificationsService={ notificationsService }
                details={ details }
              />
            </div>
          </Dialog> : null
        }
        { this.renderErrorExpenseReportDialog() }
        { integrationDialog }
        { unloadingExpenseReportsDialog }
        { limitAmountDialog }
        { detailedCertificateDialog }
        { this.renderDialogSendToEDO() }
        { this.renderMonthsExceededNotification() }
      </div>
    );
  }
}

export default Accounting;
