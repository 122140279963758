import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DecisionButtons from '../../../../components/DecisionButtons';
import Input from '../../../../components/input/index';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import styles from '../../styles/form.module.css';

const LABELS = {
  INVOICE_NUM: 'InvoiceNum',
};

class EditInvoiceForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
    changeInvoiceNumber: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { invoice } = this.props;

    this.state = {
      waitingResponse: false,
      invoice,
      valueAddedNum: null,
      defaultInvoiceNum: '',
    };
  }

  componentDidMount() {
    this.splittingInvoiceNum();
  }

  splittingInvoiceNum = () => {
    const { invoice: { InvoiceNum } } = this.props;

    if (InvoiceNum && InvoiceNum.includes('.')) {
      const EndStrInvoiceNum = InvoiceNum.split('.').pop();
      const startInvoiceNum = InvoiceNum.split('.')[0];
      this.setState({ valueAddedNum: Number(EndStrInvoiceNum), defaultInvoiceNum: startInvoiceNum });
    } else {
      this.setState({ defaultInvoiceNum: InvoiceNum });
    }
  }

  handleChangeField = (i, field, value) => this.setState((prevState) => {
    const newState = { ...prevState };

    if (field === LABELS.INVOICE_NUM) {
      newState.valueAddedNum = value;
    } else {
      newState.invoice.Details[i][field] = value;
    }

    return newState;
  });

  handleChangeEmployee = (i, j, field, value) => this.setState((prevState) => {
    const newState = { ...prevState };

    newState.invoice.Details[i].Employees[j][field] = value;

    return newState;
  });

  changeInvoiceNum = () => this.setState((prevState) => {
    const newState = { ...prevState };
    const { valueAddedNum, defaultInvoiceNum } = this.state;

    const newInvoiceNum = valueAddedNum ? `${defaultInvoiceNum}.${valueAddedNum}` : defaultInvoiceNum;

    newState.invoice.InvoiceNum = newInvoiceNum;

    return newState;
  });

  handleSave = () => {
    this.changeInvoiceNum();

    const { onClose } = this.props;
    const { invoice } = this.state;

    const save = () => {
      this.props.onSave(invoice)
        .then(() => this.setState({ waitingResponse: false }, onClose));
    };

    this.setState({ waitingResponse: true }, save);
  };

  renderHeader = () =>
    (
      <div className={ styles.row }>
        <div className={ styles['col-2-14'] }>
          <label className={ styles.label }>Номер</label>
        </div>
        <div className={ styles['col-4-14'] }>
          <label className={ styles.label }>Название</label>
        </div>
        <div className={ styles['col-4-14'] }>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <label className={ styles.label }>Фамилия</label>
            </div>
            <div className={ styles['col-1-3'] }>
              <label className={ styles.label }>Имя</label>
            </div>
            <div className={ styles['col-1-3'] }>
              <label className={ styles.label }>Отчество</label>
            </div>
          </div>
        </div>
        <div className={ styles['col-3-14'] }>
          <label className={ styles.label }>Направление</label>
        </div>
        <div className={ styles['col-1-14'] }>
          <label className={ styles.label }>Дата</label>
        </div>
      </div>
    );

  renderRow = (i, item) => {
    const { valueAddedNum, defaultInvoiceNum } = this.state;
    const { changeInvoiceNumber } = this.props;

    return (
      <div className={ styles.row } key={ i }>
        <div className={ ` ${styles['col-2-14']} ${styles.row}` }>
          <p className={ `${styles['start-num']}` }>{ defaultInvoiceNum }.</p>
          <Input
            field={ LABELS.INVOICE_NUM }
            type='number'
            value={ valueAddedNum }
            onChange={ (e, field, value) => this.handleChangeField(i, field, value) }
            disabled={ !changeInvoiceNumber }
          />
        </div>
        <div className={ styles['col-4-14'] }>
          <Input
            field='Name'
            label=''
            value={ item.Name }
            onChange={ (e, field, value) => this.handleChangeField(i, field, value) }
          />
        </div>
        <div className={ styles['col-4-14'] }>
          { this.renderEmployees(i, item.Employees) }
        </div>
        <div className={ styles['col-3-14'] }>
          <Input
            field='Direction'
            label=''
            value={ item.Direction }
            onChange={ (e, field, value) => this.handleChangeField(i, field, value) }
          />
        </div>
        <div className={ styles['col-1-14'] }>
          <Input
            field='ServiceDate'
            label=''
            value={ item.ServiceDate }
            onChange={ (e, field, value) => this.handleChangeField(i, field, value) }
          />
        </div>
      </div>
    );
  }

  renderEmployees = (i, employees) =>
    employees.map((employee, j) =>
      (
        <div className={ styles.row } key={ j }>
          <div className={ styles['col-1-3'] }>
            <Input
              field='Surname'
              label=''
              value={ employee.Surname }
              onChange={ (e, field, value) => this.handleChangeEmployee(i, j, field, value) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field='Name'
              label=''
              value={ employee.Name }
              onChange={ (e, field, value) => this.handleChangeEmployee(i, j, field, value) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field='Patronymic'
              label=''
              value={ employee.Patronymic }
              onChange={ (e, field, value) => this.handleChangeEmployee(i, j, field, value) }
            />
          </div>
        </div>
      ));

  render() {
    const { invoice: { InvoiceNum, Details }, waitingResponse } = this.state;
    const { onClose } = this.props;

    return (
      <div>
        <div className={ styles.title }>Редактирование счета { InvoiceNum }</div>
        <div className={ styles.content }>
          { this.renderHeader() }
          { Details.map((item, i) => this.renderRow(i, item)) }
        </div>
        <div className={ `${styles.row} ${styles.actions}` }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ this.handleSave }
            onCancel={ onClose }
            labelSave={ DECISIONBUTTONS.LABELS.SAVE }
          />
        </div>
      </div>
    );
  }
}

export default EditInvoiceForm;
